html,
body {
  margin: 0;
  padding: 0;
}

:root {
  --background: #fff;
  --background-clear: #ffffffcc;
  --text-color: #333;
  --disabled-text-color: #00000055;
  --heading-color: #101010;
  --background-offset: #ffffffee;
  --link-color: #090;
  --danger: #f00;
  --text-danger: #fff;
  --link-color--active: #060;
  --danger--active: #c00;
  --font-headings: 'Chakra Petch', sans-serif;
  --font: 'Open Sans', sans-serif;

  -webkit-text-size-adjust: none;

  min-height: 100vh;
  background: var(--background);
  color: var(--text-color);

  &.theme--dark {
    --background: #222;
    --background-clear: #222222cc;
    --text-color: #eee;
    --disabled-text-color: #ffffff55;
    --heading-color: #f9f9f9;
    --background-offset: #000000f9;
    --link-color: #9f9;
    --link-color--active: #9fc;
    --danger: #f00;
    --text-danger: #fee;
    --danger--active: #c00;

    color-scheme: dark;
  }
}

body,
input,
select,
textarea,
button {
  font-family: var(--font);
  font-size: 1em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-headings);
  font-weight: 400;
  font-size: 1em;
  margin: 0 0 0.5em;
  padding: 0;
  line-height: 1.5em;
  color: var(--heading-color);
}

h1 {
  font-size: 4em;
  text-align: center;
}

h2 {
  font-size: 3em;
  // text-align: center;
}

h3 {
  font-size: 2em;
}

p {
  margin: 0;
}

a {
  color: var(--link-color);
  text-decoration: none;
}

.nowrap {
  white-space: nowrap;
  display: inline-block;
}

.hidden-for-effect {
  display: inline-block;
  padding-right: 0.5ch;
  opacity: 0;
  transform: translateY(0.5em);
  transition-property: opacity, transform;
  transition-duration: 500ms;
  transition-timing-function: ease-out;

  &.active {
    opacity: 1;
    transform: translateY(0);
  }
}
